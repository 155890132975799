/* Game header styles */
.game-header-container {
  position: relative;
  width: 100%;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.game-header-content {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.game-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}

.single-day-container {
  min-width: 220px;
  transition: background-color 0.2s ease;
}

.single-day-container:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.single-day-container-odd {
  background-color: rgba(245, 245, 245, 0.5);
}

.single-day-container-even {
  background-color: white;
}

.team-code {
  font-size: 13px;
  font-weight: 600;
  color: #555;
}

.main-logo {
  object-fit: contain;
}

/* Game day date styles */
.game-day-date {
  position: sticky;
  left: 0;
  background-color: #f3f4f6;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  min-width: 70px;
}

.game-day-date .day {
  font-size: 14px;
  color: #4b5563;
  text-transform: lowercase;
}

.game-day-date .date {
  font-size: 20px;
  font-weight: 700;
}

.game-day-date .month {
  font-size: 14px;
  color: #4b5563;
  text-transform: lowercase;
}

/* Mobile navbar container */
.mobile-navbar-container {
  z-index: 50;
  display: flex;
  flex-direction: column;
}

/* Mobile specific styles */
.game-header-mobile {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Mobile match slider */
.mobile-match-slider {
  width: 100%;
  touch-action: pan-x;
  overflow-x: hidden;
  position: relative;
}

.mobile-match-slider .flex {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.mobile-match-slider .flex::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

/* Add responsiveness */
@media (max-width: 1023px) {
  .lg\:hidden {
    display: block;
  }

  .hidden {
    display: none;
  }

  /* Show mobile matches slider only on mobile */
  .mobile-match-slider {
    display: block;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  /* Hide mobile matches slider on desktop */
  .mobile-match-slider {
    display: none;
  }
}

/* match-slider.css - Kompletterande CSS */
@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: translateY(-5px);
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Förhindra scroll-beteende som påverkar fixed-element */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 144px;
  /* Höjd för båda fixerade elementen */
}

/* Anpassa huvudinnehållet */
main {
  min-height: calc(100vh - 144px);
  /* Justera för footer */
  overflow-anchor: none;
  /* Förhindrer automatisk scrolljustering */
}