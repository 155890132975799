.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  background-color: #f1f1f1;
}

.tab.active {
  font-weight: bold;
  background-color: #ddd;
  border-bottom: 2px solid #212e3b; /* Färgen för den aktiva fliken */
}

.subtab {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e7eb;
  gap: 1px;
  background-color: #fff;
  padding: 0 0.5rem;
}

.tablinks {
  padding: 10px; 
  cursor: pointer;
}

.tablinks.active {
  border-bottom: 2px solid #3b82f6;
  color: #3b82f6;
  font-weight: 500;
}

.tabcontent { 
  display: none; 
  padding: 20px; 
}

.tabcontent.active { 
  display: block; 
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: rgb(31 41 55);
  color: white;
}

thead th {
  border: none; /* Ingen border för headern */
}

input[type="number"]{
  width: 60px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}